









import Vue from "vue";
import store from "store2";

export default Vue.extend({
  name: "MemberApplicationWaitingUpload",
  computed: {},
  data: () => {
    return {};
  },
  methods: {
    returnToLoginPage() {
      this.$router.push({
        name: "Login",
      });
    },
  },
  mounted() {
    store.remove("membershipForm");
    store.remove("membershipImage");
  },
});
